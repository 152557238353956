:root {
  --grey: #8f8f8f;
  --red: red;
}
table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
tr:nth-child(odd) {
  background-color: #fff8ee;
}
tr:hover {
  background-color: #ffe3b8;
}
.styled-table th,
td {
  padding: 4px 8px;
}
a {
  text-decoration: none;
  font-size: 16px;
}
a:link {
  color: #ffba50;
}
a:hover {
  color: #ff8100;
}
h1 {
  color: rgb(102, 102, 102);
  text-align: center;
}
.subtitle {
  font-size: 10px;
  text-align: center;
  margin-top: -15px;
  color: var(--grey);
}
.count {
  font-size: 10px;
  color: var(--red);
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-tabs-top > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-ed5zg0).ant-tabs-bottom > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-ed5zg0).ant-tabs-top > div > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-ed5zg0).ant-tabs-bottom
  > div
  > .ant-tabs-nav {
  margin: 0px !important;
}

table {
  margin: 0px !important;
}
