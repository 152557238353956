.App {
  text-align: left;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bebebe;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(158, 158, 158);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(104, 104, 104);
}

.logo {
  height: 45vh;
  width: fit-content;
}

.section0 h1 {
  font-size: calc(18px + 2vmin);
  font-weight: 500;
}

.signature {
  max-height: 50vh;
  filter: invert(14%) sepia(16%) saturate(23%) hue-rotate(14deg) brightness(97%)
    contrast(84%);
}


.rowstyle {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  justify-content: space-between;
}

.datablock {
  display: block;
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 12px;
}
.datablockicon {
  display: block;
}
.tooltip {
  background-color: #363636;
  border-radius: 5%;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
}
.datablock:hover .tooltip {
  visibility: visible;
}

.datablocktext {
  display: none;
}

.datablock:hover a .datablockicon {
  display: none;
}
.datablock:hover a .datablocktext {
  display: block;
}

.section3 {
  background-color: #d47355;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.section4 {
  background-color: #d4d644;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section5 {
  background-color: #69b869;
  min-height: 100vh;
  padding: 40px 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
